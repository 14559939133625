import React, {useState} from "react";
import "./index.scss";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Link } from "gatsby";
import LoginForm from "../components/LoginForm";
import NewsletterForm from "../components/NewsletterForm";
import Footer from "../components/Footer";
import {TextField} from "@fluentui/react/lib/TextField";
import {useForm} from "react-hook-form";
import {InquirySchema, InquiryValidationModel} from "../helpers/InquiryFormModel";
import {yupResolver} from "@hookform/resolvers/yup";
import {NewsletterSchema, NewsletterValidationModel} from "../helpers/NewsletterFormModel";


var unSubmittedState = {
    isSubmitted: false,
    isError: false,
    submitTitleValue : "Subscribe to the Oodal Newsletter",
    subText: "Join us for AI Announcements and More!"
};

var submitSuccessState = {
    isSubmitted: true,
    isError: false,
    submitTitleValue: "Thank You!",
    subText: "Thank you for subscribing to our newsletter."
};

var submitErrorState = {
    isSubmitted: true,
    isError: true,
    submitTitleValue: "OOPS! Something went wrong",
    subText: "Please try submitting your message again, or contact us directly at >> inquiries@oodal.co <<"
};

let lastSubmission: NewsletterValidationModel | undefined = undefined;

// In Gatsby, Page == Component
export default function Newsletter() {

    const { register, formState: { errors }, handleSubmit } = useForm<NewsletterValidationModel>({ resolver: yupResolver(NewsletterSchema)});
    const [ submissionData, setSubmissionData ] = useState(unSubmittedState);

    const onError = (data: any) => {
        // errors for both API and form validation failure
        setSubmissionData(submitErrorState);
    };

    const onSubmit = (data: NewsletterValidationModel | undefined) => {
        if(data)
        {
            lastSubmission = data;

            /*
            var submitHandler = new TwilioHandler();
            submitHandler
                .SendContactForm(data.Inquiry.Email, data.Inquiry.Name, data.Inquiry.Message)
                .then((success) => {
                    if(success){
                        setSubmissionData(submitSuccessState);
                    }
                })
                .catch((err) => {
                    onError(err);
                });

             */
            setSubmissionData(submitSuccessState);
        } else {
            setSubmissionData(unSubmittedState);
        }
    }

    return (
        <main className="container">
            <script type="text/javascript" id="hs-script-loader" async defer
                    src="//js.hs-scripts.com/47357369.js"></script>
            <title>Announcements Sign Up</title>
            <div className="top-container login-page">
                <header>
                    <nav>
                        <div className="logo-container">
                            <Link to="/">
                                <image className="logo"/>
                            </Link>
                        </div>
                        <div className="menuItems">
                            <Link to="/" className="navButton menuItem-1">Contact</Link>
                            <Link to="/login" className="navButton menuItem-2">Partner Login</Link>
                        </div>
                    </nav>
                </header>
                <section id="newsletterFrame" className="bottomNoGraphic">
                    <div className="webform inquiry-form">
                        <form name="newsletter" onSubmit={handleSubmit(onSubmit, onError)} method="POST">
                            <h2>{submissionData.submitTitleValue}</h2>
                            {
                                !submissionData.isSubmitted
                                && <p>{submissionData.subText}</p>
                            }
                            {
                                !submissionData.isSubmitted &&
                                <>
                                    <TextField
                                        id="txtName"
                                        placeholder="Email"
                                        {...register("Newsletter.Email")}
                                    />
                                    <DefaultButton
                                        text="Subscribe"
                                        type="submit"
                                        onSubmit={(event) => event.preventDefault()}
                                        allowDisabledFocus
                                    />
                                </>
                            }
                            {
                                submissionData.isSubmitted &&
                                <>
                                    <p>{submissionData.subText}</p>
                                </>
                            }
                            {
                                submissionData.isError &&
                                <>
                                    <DefaultButton
                                        className="tryAgainBtn"
                                        text="Try Again"
                                        type="submit"
                                        onClick={(event) =>{
                                            event.preventDefault();
                                            try{
                                                setSubmissionData(unSubmittedState);
                                            }catch {}
                                        }}
                                        allowDisabledFocus
                                    />
                                </>
                            }
                        </form>
                    </div>
                </section>

                <Footer/>
            </div>

        </main>
    )
}



